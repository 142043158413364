import React from 'react';
import '../App.css';

function Projects({ darkMode, language }) {
  return (
    <section id="projects" className={`sectionp unselectable ${darkMode ? 'dark' : 'light'}`}>
      <h2>{language === 'en' ? 'Projects' : 'Projetos'}</h2>
      <div className="project">
        <h3>Cloud Storage</h3>
        <p>{language === 'en' ? "My first project was building a Cloud Storage with" : "O meu primeiro projeto foi construir um armazenamento na Cloud com a"} <a href="https://nextcloud.com/" className="custom-link" target="_blank" rel="noopener noreferrer">NextCloud</a> 
            {language === 'en' ? " on a Ubuntu Server" : " num servidor Ubuntu"} <a href="https://releases.ubuntu.com/18.04.6/" className="custom-link" target="_blank" rel="noopener noreferrer">18.04</a>, {language === 'en' ? "it was hosted for a year and half but I shut it down because I wasn't able to afford the domain." : 
            "esteve hospedado por um ano e meio, mas desliguei-o porque não tinha dinheiro para pagar o domínio."}</p>
      </div>
      <div className="project">
        <h3>Multi Theft Auto</h3>
        <p>{language === 'en' ? "For my second project I came up with the idea of developing a server of" : "Para o meu segundo projeto, tive a ideia de desenvolver um servidor de"} <a href="https://multitheftauto.com/" className="custom-link" target="_blank" rel="noopener noreferrer">MTA:SA</a>, 
            {language === 'en' ? " that is a multiplayer modification of GTA:San Andreas and it's LUA based." : " que é uma modificação multiplayer do GTA: San Andreas e é baseado em LUA."}</p> 
        <p>{language === 'en' ? "After developing the server I created a web page where the main page is where people can buy in-game VIP with different tiers, it has an About and a Contact page and it was to have a register/login page but there were more important things that came up." : 
            "Depois de desenvolver o servidor, criei uma página web onde a página principal é onde as pessoas podem comprar VIP in-game com diferentes níveis, possui ainda uma página 'Sobre' e uma página de 'Contacto', deveria ter uma página de registo/login mas surgiram coisas mais importantes."}</p>
      </div>
      <div className="project">
        <h3>Discord BOT</h3>
        <p>{language === 'en' ? "This project idea came before the above ones but i just started after complete them. I made the Discord BOT in javascript with the" : "Esta ideia de projeto veio antes dos anteriores, mas só comecei depois de os concluir. Fiz o Discord BOT em javascript com o"} <a href="https://nodejs.org/en" className="custom-link" target="_blank" rel="noopener noreferrer">Node.js</a>,  
            {language === 'en' ? " this was made to manage a Discord Server of a friend that started to streaming on" : " isto foi feito para gerenciar um servidor de Discord de um amigo que começou a fazer streams na"} <a href="https://twitch.tv/" className="custom-link" target="_blank" rel="noopener noreferrer">Twitch</a>.</p> 
        <p>{language === 'en' ? "I'm gonna show some files and a video:" : "Vou mostrar alguns ficheiros e um vídeo:"}</p>
        <p><a href="http://diogos-portfolio.ddns.net/files/bot_info.PNG" className="custom-link" target="_blank" rel="noopener noreferrer">{language === 'en' ? "Bot Info" : "Informação do bot"}</a></p>
        <p><a href="http://diogos-portfolio.ddns.net/files/help_command.PNG" className="custom-link" target="_blank" rel="noopener noreferrer">{language === 'en' ? "Help command" : "Comando de ajuda"}</a></p>
        <p><a href="http://diogos-portfolio.ddns.net/files/tickets.png" className="custom-link" target="_blank" rel="noopener noreferrer">Tickets</a></p>
        <p><a href="http://diogos-portfolio.ddns.net/files/discord_bot.mp4" className="custom-link" target="_blank" rel="noopener noreferrer">{language === 'en' ? "Video" : "Vídeo"}</a></p>
      </div>
      {/* Add more projects as needed */}
    </section>
  );
}

export default Projects;