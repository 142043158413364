import React from 'react';
import './App.css';

function Header({ toggleLanguage, language, darkMode, toggleDarkMode }) {
    return (
        <div className={`Header ${darkMode ? 'dark' : 'light'}`}>
            <header id="home">
                <nav>
                    <ul>
                        <li><a href="#home">{language === 'en' ? 'Home' : 'Início'}</a></li>
                        <li><a href="#about">{language === 'en' ? 'About' : 'Sobre'}</a></li>
                        <li><a href="#projects">{language === 'en' ? 'Projects' : 'Projetos'}</a></li>
                        <li><a href="#cv">{language === 'en' ? 'Curriculum' : 'Currículo'}</a></li>
                        <li><a href="#contact">{language === 'en' ? 'Contacts' : 'Contactos'}</a></li>
                        <li>
                            <button onClick={toggleDarkMode}>
                                {darkMode ? 'Light Mode' : 'Dark Mode'}
                            </button>
                        </li>
                        <li>
                            <button onClick={toggleLanguage}>
                                {language === 'en' ? 'Português' : 'English'}
                            </button>
                        </li>
                    </ul>
                </nav>
            </header>

            <style>{`
                ::-webkit-scrollbar {
                    background-color: ${darkMode ? '#424242' : '#f1f1f1'};
                    width: 16px;
                }
                ::-webkit-scrollbar-thumb {
                    background-color: ${darkMode ? '#7b7b7b' : '#a8a8a8'};
                }
                }
                ::-webkit-scrollbar-button {
                    background-color: ${darkMode ? '#7b7b7b' : '#a8a8a8'};
                    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAQAAAAngNWGAAAAP0lEQVR42mNkAAIAAAoAAv/lxKUAAAAASUVORK5CYII=');
                    background-repeat: no-repeat;
                    background-size: contain;
                }
            `}</style>

        </div>
    );
}

export default Header;
