import React, { useState, useEffect } from 'react';
import './App.css';
import Header from './Header';
import About from './js/About';
import Projects from './js/Projects';
import CV from './js/CV';
import Contact from './js/Contact.js';
import Footer from './js/Footer.js';
import Body from './js/Body.js';
//import Icons from './js/icons.js';

function App() {
    // State for language
    const [language, setLanguage] = useState(() => {
        const savedLanguage = localStorage.getItem('language');
        return savedLanguage ? savedLanguage : 'en'; // Default language is English if not set
    });

    // Function to toggle between languages
    const toggleLanguage = () => {
        setLanguage(language === 'en' ? 'pt' : 'en');
    };

    // Effect to save language preference to localStorage
    useEffect(() => {
        localStorage.setItem('language', language);
    }, [language]);

    // Check if there's a theme preference stored in localStorage, otherwise default to light mode
    const savedTheme = localStorage.getItem('theme');
    const [darkMode, setDarkMode] = useState(savedTheme === 'dark');

    // Save the theme preference to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem('theme', darkMode ? 'dark' : 'light');
    }, [darkMode]);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    return (
        <div className={`App ${darkMode ? 'dark' : 'light'}`}>
            <Header toggleLanguage={toggleLanguage} toggleDarkMode={toggleDarkMode} language={language} darkMode={darkMode} />
            <Body language={language} darkMode={darkMode} />
            <About language={language} darkMode={darkMode} />
            <Projects language={language} darkMode={darkMode} />
            <CV language={language} darkMode={darkMode} />
            <Contact language={language} darkMode={darkMode} />
            {/*<Icons />*/}
            <Footer language={language} />
        </div>
    );
}

export default App;
