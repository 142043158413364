import React from 'react';
import '../App.css';

function CV({ language, darkMode }) {
  return (
    <section id="cv" className={`section unselectable ${darkMode ? 'dark' : 'light'}`}>
        <h2>{language === 'en' ? 'Curriculum & Certificate' : 'Currículo & Certificado'}</h2>
        <p><a href="http://diogos-portfolio.ddns.net/files/DiogoSantos_CV.pdf" className="custom-link" target="_blank" rel="noopener noreferrer">{language === 'en' ? 'Portuguese' : 'Português'}</a></p>
        <p><a href="http://diogos-portfolio.ddns.net/files/DiogoSantos_CV_EN.pdf" className="custom-link" target="_blank" rel="noopener noreferrer">{language === 'en' ? 'English' : 'Inglês'}</a></p>
        <p><a href="http://diogos-portfolio.ddns.net/files/Certificate.pdf" className="custom-link" target="_blank" rel="noopener noreferrer">{language === 'en' ? 'Certificate' : 'Certificado'}</a></p>
    </section>
  );
}

export default CV;
