import React from 'react';
import '../App.css';
import '../Header.js';
import Age from './Age.js';

function About({ language, darkMode }) {
    // State for language
    return (
      <div className={`About ${language === 'en' ? 'english' : 'portuguese'} ${darkMode ? 'dark' : 'light'}`}>
        <section id="about" className="section unselectable">
            <h2>{language === 'en' ? 'About Me' : 'Sobre Mim'}</h2>
            <p>{language === 'en' ? "I'm Diogo Santos, I'm " : "Eu sou o Diogo Santos, tenho "} <Age /> {language === 'en' ? ' years old' : ' anos'}</p>
        </section>
      </div>
    );
}
export default About;
