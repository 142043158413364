import React, { useState, useEffect } from 'react';

function Age() {
    const [age, setAge] = useState(0);

    useEffect(() => {
        const calculateAge = () => {
            const today = new Date();
            let age = today.getFullYear() - 2002;
            const hasBirthday = today.getMonth() >= 11 - 1 && today.getDate() >= 20;
            if (!hasBirthday) {
                age--;
            }
            setAge(age);
        };

        calculateAge();

        const interval = setInterval(calculateAge, 60000);

        return () => clearInterval(interval);
    }, []);

    return <span>{age}</span>;
}
export default Age;